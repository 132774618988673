<template>
  <section class="contact-and-send-message">
    <section class="contact">
      <h2 class="contact__title">Kontakt</h2>
      <address class="not-italic contact__address">
        Adres: <br />
        53-343 Wrocław <br />
        ul.Komandorska 18
      </address>
      <text-links classes="contact__link" href="Tel:508217126"
        >Tel.: 508 217 126</text-links
      >
      <text-links class="contact__link" href="mailto:info@cfblackstar.com">
        Email: info@cfblackstar.com
      </text-links>
      <div class="contact__social-media">
        <a
          href="https://www.facebook.com/CrossBlackstar"
          class="contact__link-img"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src="../assets/img/webp/facebook-app-logo.webp"
            alt="Facebook"
            class="contact__img"
          />
        </a>
        <a
          href="https://www.instagram.com/crossfit_blackstar/"
          class="contact__link-img"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src="../assets/img/webp/instagram-icon.webp"
            alt="Instagram"
            class="contact__img"
          />
        </a>
      </div>
    </section>
    <section class="send-message">
      <h2 class="send-message__title">Wyślij Wiadomość</h2>
      <form action="" class="send-message__form" @submit.prevent="validationForm">
        <base-input
          type="text"
          placeholder="Imię*"
          name="name"
          :classes="nameInputClasses"
          @input="validateName"
        />
        <span :class="chatBubbleNameClasses"> *To pole musi zostać wypełnione! </span>
        <base-input
          type="text"
          placeholder="Temat"
          name="topic"
          classes="send-message__input send-message__input--topic"
        />
        <base-input
          type="email"
          placeholder="Email*"
          name="email"
          :classes="emailInputClasses"
          @input="validateEmail"
        />
        <span :class="chatBubbleEmailClasses" ref="emailBubble">
          *To pole musi zostać wypełnione!
        </span>
        <base-input
          type="textarea"
          placeholder="Wiadomość"
          name="message"
          classes="send-message__input send-message__input--message"
        />
        <div
          class="g-recaptcha"
          data-sitekey="6LdtXFQbAAAAAOEpTB2beGiHnomnYMfexcM1IMMo"
        ></div>
        <base-button classes="send-message__submit dark-btn" type="submit">
          Wyślij
          <img
            src="../assets/img/loading.svg"
            alt="Ładowanie"
            class="send-message__loader"
            ref="loader"
          />
        </base-button>
        <span class="loader-message" ref="loaderMessage"
          >Wysyłanie wiadomość trwa dłużej niż zwykle. Sprawdź swoje połączenie z
          internetem.
        </span>
      </form>
    </section>
  </section>
</template>

<script>
/* eslint-disable prettier/prettier */
/* eslint-disable no-useless-escape */
/* eslint-disable no-undef*/
import emailjs from "emailjs-com";

export default {
  data() {
    return {
      isNameValueCorrect: true,
      isEmailValueCorrect: true,
    };
  },
  computed: {
    nameInputClasses() {
      return {
        "send-message__input": true,
        "send-message__input--name": true,
        "send-message__input--error": !this.isNameValueCorrect,
      };
    },
    chatBubbleNameClasses() {
      return { "chat-bubble": true, block: !this.isNameValueCorrect };
    },
    emailInputClasses() {
      return {
        "send-message__input": true,
        "send-message__input--email": true,
        "send-message__input--error": !this.isEmailValueCorrect,
      };
    },
    chatBubbleEmailClasses() {
      return {
        "chat-bubble": true,
        "chat-bubble--email": true,
        block: !this.isEmailValueCorrect,
      };
    },
  },
  methods: {
    validateName() {
      const inputs = document.querySelectorAll(".send-message__input");

      if (inputs[0].value.trim() === "") {
        this.isNameValueCorrect = false;
      } else {
        this.isNameValueCorrect = true;
      }
    },
    validateEmail() {
      const inputs = document.querySelectorAll(".send-message__input");
      const emailRegex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

      if (inputs[2].value.trim() === "") {
        this.isEmailValueCorrect = false;
      } else if (emailRegex.test(inputs[2].value.trim()) === false) {
        this.$refs.emailBubble.innerHTML = "*Zawartość tego pola nie jest email-em";
        this.isEmailValueCorrect = false;
      } else {
        this.isEmailValueCorrect = true;
      }
    },
    validationForm(e) {
      this.validateName();
      this.validateEmail();

      if (this.isEmailValueCorrect === true && this.isNameValueCorrect === true)
        this.sendEmail(e.target);
    },
    sendEmail(form) {
      const loader = this.$refs.loader;
      const loaderMessage = this.$refs.loaderMessage;
      const submitBtn = document.querySelector(".send-message__submit");
      const inputs = form.querySelectorAll(".send-message__input");

      submitBtn.setAttribute("disabled", "disabled");
      loaderMessage.classList.remove("block");
      loader.classList.add("block");

      const timeout = setTimeout(() => {
        loaderMessage.classList.add("block");
      }, 2500);

      emailjs
        .sendForm(
          "service_f6btcnd",
          "template_dnsinth",
          form,
          "user_xphGDl69n6FW5fOBGhzyd"
        )
        .then(
          (res) => {
            if (res.status === 200) {
              loaderMessage.innerHTML =
                "Twoja wiadomość zostałą wysłana pomyślnie. Dziękujemy za skontaktowanie się z nami :)";
              loaderMessage.classList.add("block");
              loaderMessage.classList.remove("text-red-500");
              inputs.forEach((input) => {
                localStorage.removeItem(input.getAttribute("name"));
                input.value = "";
              });
            }
            clearTimeout(timeout);
            submitBtn.removeAttribute("disabled");
            loader.classList.remove("block");
          },
          (err) => {
            let message =
              "Podczas wysyłania wiadomości wystąpił błąd. Prosimy spróbować ponownie.";

            if (!navigator.onLine) {
              message =
                "Brak połączenia z internetem. Prosimy połączyć się z internetem i wysłać wiadomość ponownie.";
            } else if (err.text === "The g-recaptcha-response parameter not found") {
              message =
                "Wiadmość nie została wysłana. Potwierdź, że nie jesteś robotem i spróbuj ponownie :).";
            }

            loaderMessage.innerHTML = message;
            loaderMessage.classList.add("text-red-500", "block");
            clearTimeout(timeout);
            submitBtn.removeAttribute("disabled");
            loader.classList.remove("block");
          }
        );
    },
  },
  beforeRouteLeave(_, __, next) {
    if (this.$store.getters.isPhoneMenuOpen) {
      this.$store.commit("openClosePhoneMenu");
    }
    next();
  },
  mounted() {
    const existRecaptchaJs = document.querySelector(
      'script[src="https://www.google.com/recaptcha/api.js"]'
    );
    existRecaptchaJs?.remove();

    const recaptchaJs = document.createElement("script");
    recaptchaJs.setAttribute("src", "https://www.google.com/recaptcha/api.js");
    document.head.appendChild(recaptchaJs);
  },
};
</script>

<style lang="scss" scoped>
@import "./scss/contact.scss";
</style>
